import { CoreoApp } from '../types';

export type AppStyling = Pick<CoreoApp, 'css'>

class ThemeService {

  readonly SELECTOR = 'coreo-themeable';

  private style: HTMLStyleElement;
  private styleRef: HTMLDivElement;

  constructor() {
    this.style = document.createElement('style');
    this.style.setAttribute('sty-id', this.SELECTOR);

    document.querySelector('head').appendChild(this.style);

    this.styleRef = document.createElement('div');
    this.styleRef.classList.add(this.SELECTOR);
    this.styleRef.setAttribute('hidden', 'hidden');
    document.querySelector('body').appendChild(this.styleRef);

    this.reset();
  }

  // Public
  //

  update(styling: AppStyling) {
    this.css(styling.css);
  }

  reset() {
    const rules = this.style.sheet.cssRules;

    for (let i = 0; i < rules.length; i++) {
      this.style.sheet.deleteRule(i);
    }

    this.style.sheet.insertRule(`.${this.SELECTOR} {}`, 0);

    const rule = this.style.sheet.cssRules[0] as CSSStyleRule;
    rule.selectorText = `.${this.SELECTOR}`;
  };

  private css(css?: string) {
    const rule = this.style.sheet.cssRules.item(0) as CSSStyleRule;

    const custom = css?.replace(/(\r\n|\n|\r)/gm, '').split(';');

    if (!custom) {
      return;
    }

    custom.forEach(customRule => {
      const r = customRule.split(':');

      if (r.length === 2) {
        rule.style.setProperty(r[0], r[1]);
      }
    });
  }

  getPropertyValue(property: string) {
    return getComputedStyle(this.styleRef).getPropertyValue(property);
  }

  getPrimaryColor() {
    return this.getPropertyValue('--ion-color-primary');
  }
}

export default new ThemeService();
